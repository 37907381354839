<template>
  <v-container fluid class="pa-0">
    <vue-headful
      title="Login | Finde Deinen Sport"
      description="Melde dich an in der Finde Deinen Sport Plattform an."
    />
    <v-row style="min-height: 100vh;" justify="center">
      <v-col
        lg="4"
        md="6"
        cols="11"
        class="lighten-4 pa-4 rounded-xl"
        align-self="center"
      >
        <a to="/start">
          <v-row justify="center">
            <v-col md="8" cols="6">
              <v-img src="/img/3x/finde-deinen-sport@3x.png" height="150" contain class="pb-0" />
            </v-col>
          </v-row>
        </a>
        <v-row justify="center" class="my-2">
          <v-col align-self="center">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="auto" align-self="center">
            <h2 class="text-center">Bitte melde dich an</h2>
          </v-col>
          <v-col align-self="center">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="10">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              v-on:keyup.enter="submit"
            >
              <v-text-field
                v-model="form.email"
                :rules="[rules.required]"
                label="E-Mail"
                color="primary"
                required
                autofocus
                filled
                rounded
              ></v-text-field>
              <v-text-field
                v-model="form.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show1 ? 'text' : 'password'"
                name="Passwort"
                label="Passwort"
                color="primary"
                filled
                rounded
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-form>
          </v-col>
          <v-expand-transition>
            <v-col cols="10" v-if="error">
              <v-card
                color="transparent"
                class="elevation-0 text-center pa-1 error--text"
              >
                <h5 v-if="error.method == 'iTurnier'">{{ error.text }}</h5>
              </v-card>
            </v-col>
          </v-expand-transition>
          <v-col cols="10">
            <v-btn
              block
              large
              rounded
              :loading="loadermail"
              @click="submit"
              color="primary"
            >
              LOGIN
            </v-btn>
          </v-col>
        </v-row>
        <v-card color="transparent" class="elevation-0">
          <v-row justify="center" class="mt-5 mb-2">
            <v-col align-self="center">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="auto" align-self="center">
              <h4>DU HAST NOCH KEIN KONTO?</h4>
            </v-col>
            <v-col align-self="center">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card>
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn large rounded to="/jetzt-starten">
              JETZT REGISTRIEREN
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../store'
import router from '../../router/index'
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      valid: true,
      error: null,
      show1: false,
      loadermail: false,
      loaderfb: false,
      loaderg: false,
      password: 'Password',
      rules: {
        required: (value) => !!value || '',
      },
    }
  },
  mounted() {
    if(this.user.data){
      router.push('/')
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      auth: 'auth',
    }),
  },
  methods: {
    submit() {
      this.loadermail = true
      this.error = null
      signInWithEmailAndPassword(
        this.auth,
        this.form.email,
        this.form.password,
      ).catch((err) => {
        this.loadermail = false
        this.error = {
          text: this.translateError(err),
          code: err.code,
        }
        console.log(this.error)
      })
    },
    fblogin() {
      this.loaderfb = true
      this.error = null
      var provider = new firebase.auth.FacebookAuthProvider()
      firebase.auth().useDeviceLanguage()
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          if (result.user) {
            if (!firebase.auth().currentUser.emailVerified) {
              firebase.auth().currentUser.sendEmailVerification()
            }
            //router.push('/');
          }
        })
        .catch((err) => {
          this.loaderfb = false
          this.error = {
            text: this.translateError(err),
            code: err.code,
            method: 'Facebook',
          }
          console.log(this.error)
        })
    },
    glogin() {
      this.loaderg = true
      this.error = null
      var provider = new firebase.auth.GoogleAuthProvider()
      firebase.auth().useDeviceLanguage()
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          if (result.user) {
            if (!firebase.auth().currentUser.emailVerified) {
              firebase.auth().currentUser.sendEmailVerification()
            }
            //router.push('/');
          }
        })
        .catch((err) => {
          this.loaderg = false
          this.error = {
            text: this.translateError(err),
            code: err.code,
            method: 'Google',
          }
          console.log(this.error)
        })
    },
    translateError(error) {
      switch (error.code) {
        case 'auth/wrong-password':
          return 'Das eingegene Passwort ist falsch oder für diesen Account existiert noch kein Passwort-Login. Nutze stattdessen den Facebook- bzw. Google-Login.'
        case 'auth/user-not-found':
          return 'Es existiert kein Account mit der eingegenen E-Mail Adresse.'
        case 'auth/user-disabled':
          return 'Der Account wurde deaktiviert.'
        case 'auth/invalid-email':
          return 'Ungültige E-Mail Adresse.'
        case 'auth/account-exists-with-different-credential':
          return 'Es existiert bereits ein Account mit dieser E-Mail Adresse.'
        case 'auth/popup-blocked':
          return 'Das Login-Popup wurde von deinem Browser geblockt. Überprüfe deine Einstellungen und versuche es erneut.'
        case 'auth/popup-closed-by-user':
          return 'Der Login-Prozess wurde unterbrochen. Bitte versuche es erneut.'
        default:
          console.log('Unknown Error: ' + error)
          return 'Es ist ein unbekannter Fehler aufgetreten.'
      }
    },
  },
}
</script>
